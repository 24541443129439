import React from "react";
import { connect } from "react-redux";
import Util from "../../util/Util";
import { resetPassword } from "../../actions/passwordRecoveryTokens/passwordRecoveryTokens";
import { FormattedMessage, injectIntl } from "react-intl";
import { NotificationManager } from "react-notifications";
import "react-notifications/lib/notifications.css";
class NewPwdForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordConfirmation: "",
      disabled: false,
      spinner: "fa fa-spinner fa-spin d-none",
      btnMessage: <FormattedMessage id="Reset" />,
    };
  }

  handleSubmit(e) {
    e.preventDefault();
    e.stopPropagation();

    if (
      !this.props.match.params.token ||
      this.state.disabled ||
      this.hasEmptyField()
    )
      return;

    // Check password parity
    if (this.state.password !== this.state.passwordConfirmation) {
      NotificationManager.error(
        this.props.intl.formatMessage({ id: "Password.Does.Not.Match" })
      );
      return;
    }

    // Check password syntax
    if (!this.checkValidPasswordForRecovery(this.state.password)) {
      NotificationManager.error(
        this.props.intl.formatMessage({ id: "Password.Not.Conform" })
      );
      return;
    }

    this.setState({ disabled: true });
    this.setState({ spinner: "fa fa-spinner fa-spin" });
    this.setState({ btnMessage: <FormattedMessage id="Plz.Wait" /> });

    let that = this;
    function onResetPasswordSuccess() {
      that.props.history.push("/reset/success");
    }

    var token = this.props.match.params.token;
    var url = this.props.match.params.company;

    var data = {
      password: this.state.password,
      url: url,
    };

    this.props.onResetPassword(token, data, onResetPasswordSuccess);
  }

  // New method to validate full username during recovery process
  // https://www.thepolyglotdeveloper.com/2015/05/use-regex-to-test-password-strength-in-javascript/
  checkValidPasswordForRecovery(password) {
    var pattern = new RegExp(
      "^(((?=.*[a-z])(?=.*[A-Z]))|((?=.*[a-z])(?=.*[0-9]))|((?=.*[A-Z])(?=.*[0-9])))(?=.{6,})"
    );
    return pattern.test(password);
  }

  hasEmptyField() {
    // Check password parity
    if (this.state.password !== this.state.passwordConfirmation) {
      return true;
    }

    return !Util.emptyString(this.state.password) &&
      !Util.emptyString(this.state.passwordConfirmation)
      ? false
      : true;
  }

  onFieldChange(field, value) {
    this.setState({ [field]: value });
  }

  render() {
    var formStyle = {
      paddingTop: 6 + "em",
      paddingBottom: 6 + "em",
    };

    return (
      <div className="vertical-center no-bg p-0 m-0 text-center">
        <div className="container pt-5 pl-5 pr-5 pb-0 mw-600">
          <img
            className="logo-login"
            alt=""
            src="./images/svg/logo_e4BIZZ.svg"
          />

          <form style={formStyle} onSubmit={(e) => this.handleSubmit(e)}>
            <div className="form-group row">
              <label htmlFor="password">
                <FormattedMessage id="Reset.Password" />
              </label>{" "}
              :
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text bg-light">
                    <i className="fa fa-key fa-fw"></i>
                  </div>
                </div>
                <input
                  type="password"
                  id="password"
                  name="password"
                  className="form-control"
                  value={this.state.password}
                  onChange={(e) =>
                    this.onFieldChange("password", e.target.value.trim())
                  }
                />
              </div>
            </div>

            <div className="form-group row">
              <label htmlFor="password">
                <FormattedMessage id="Confirm.Password" />
              </label>{" "}
              :
              <div className="input-group">
                <div className="input-group-prepend">
                  <div className="input-group-text bg-light">
                    <i className="fa fa-key fa-fw"></i>
                  </div>
                </div>
                <input
                  type="password"
                  id="passwordConfirmation"
                  name="passwordConfirmation"
                  className="form-control"
                  value={this.state.passwordConfirmation}
                  onChange={(e) =>
                    this.onFieldChange(
                      "passwordConfirmation",
                      e.target.value.trim()
                    )
                  }
                />
              </div>
            </div>

            <button
              type="submit"
              className="btn btn-info"
              disabled={this.state.disabled || this.hasEmptyField()}
            >
              <i id="spinner" className={this.state.spinner}></i>{" "}
              {this.state.btnMessage}
            </button>
          </form>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onResetPassword: (token, data, successCallback, failureCallback) =>
      dispatch(resetPassword(token, data, successCallback, failureCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(NewPwdForm));
